.SimpleLink {
  color: var(--Red);
  font-weight: 500;
  font-size: 1.2rem;
}
@media screen and (max-width: 740px) {
  .SimpleLink {
    font-size: 1rem;
  }
}
