.imageBoxContainer {
  position: relative;
}
.imageBoxContainer img {
  max-width: 100%;
}
.imageBoxContainer p {
  text-align: center;
  position: absolute;
  width: 90%;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.3rem 1rem;
  border-radius: 30px;
  color: var(--DarkGrey);
  background-color: var(--LightGreyTransparant);
  transition: all 0.2s;
}
.imageBoxContainer:hover p {
  color: var(--White);
  background-color: var(--Red);
}
