.link {
  background-color: var(--Red);
  color: var(--White);
  padding: 0.5em 1em;
  border: 1px solid var(--Red);
  border-radius: 15px;
  font-weight: 500;
  font-size: 1.2rem;
  transition: all 0.2s;
}
.link:hover {
  background-color: transparent;
  color: var(--Red);
}
@media screen and (max-width: 740px) {
  .link {
    padding: 0.4em 0.8em;
    font-size: 1rem;
  }
}
