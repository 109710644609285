.ModalWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  display: block;
  position: relative;
  width: 100%;
  max-width: 1300px;
  max-height: 85vh;
  z-index: 999;
  overflow: auto;
  padding: 40px 30px;
  margin: 20px;
  border-radius: 10px;
  animation: ModalOpen 200ms linear forwards;
}
.modal.closing {
  animation: ModalClose 200ms linear forwards;
}
@keyframes ModalOpen {
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
  50% {
    opacity: 0.8;
    transform: scale3d(0.5, 0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes ModalClose {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.modal .modalContainer {
  width: 100%;
  max-width: 1300px;
}
.modal img {
  width: 55%;
}
.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  animation: openBackdrop 300ms linear forwards;
}
.backdrop.closing {
  animation: closeBackdrop 300ms linear forwards;
}
@keyframes openBackdrop {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }

  100% {
    background-color: rgba(0, 0, 0, 0.75);
  }
}
@keyframes closeBackdrop {
  0% {
    background-color: rgba(0, 0, 0, 0.75);
  }

  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  padding: 20px;
  cursor: pointer;
  transition: color 300ms;
}

.modalDescContainer {
  margin-top: 20px;
}
.label {
  font-size: 0.9rem;
  font-weight: 400;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 30px;
}

.modalDescContainer a {
  background-color: var(--Red);
  color: var(--White);
  padding: 0.5em 1em;
  border: 1px solid var(--Red);
  border-radius: 15px;
  font-weight: 500;
  font-size: 1.2rem;
  transition: all 0.2s;
}
.modalDescContainer a:hover {
  background-color: transparent;
  color: var(--Red);
}
@media screen and (max-width: 740px) {
  .modalDescContainer a {
    padding: 0.4em 0.8em;
    font-size: 1rem;
  }
}

/* .modalDescContainer a {
  text-decoration: none;
  color: white;
  font-size: 1.3rem;
  padding: 8px 15px;
  border-radius: 30px;
  background-color: black;
  box-shadow: 1px 2px 10px -3px #ec4d37;
  transition: all 300ms;
  margin: 10px 0;
}
.modalDescContainer a:hover {
  text-decoration: none;
  color: white;
  background-color: #ec4d37;
  box-shadow: 1px 2px 15px -2px rgb(255, 255, 255);
} */

.modalDescContainer .modalText {
  margin: 15px 0 30px 0;
}
