.cardContainer {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: var(--Black);
}
.imageContainer {
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--Red);
}
.imageContainer img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}
.contentContainer {
  background-color: #f5f5f5;
  padding: 15px;
  padding-bottom: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contentTitle {
  margin-top: 10px;
  margin-bottom: 20px;
}

.link {
  display: inline-block;
  cursor: pointer;
  user-select: none;
  background-color: var(--Red);
  color: var(--White);
  padding: 0.5em 1em;
  border: 1px solid var(--Red);
  border-radius: 15px;
  font-weight: 500;
  font-size: 1.2rem;
  transition: all 0.2s;
}
.link:hover {
  background-color: transparent;
  color: var(--Red);
}
@media screen and (max-width: 740px) {
  .link {
    padding: 0.4em 0.8em;
    font-size: 1rem;
  }
}
