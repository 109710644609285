.portfolioBadge {
  display: inline-block;
  padding: 0.3em 1em;
  background-color: rgb(0, 0, 0);
  color: var(--White);
  border-radius: 50px;
}
.Personal {
  background-color: var(--Black);
}
